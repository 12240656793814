.transfer-part {
  background: var(--color-white);
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 32px;

  .transfer-part__inner {
    width: 660px;
    @media (max-width: 1200px) {
      width: calc(100% - 32px);
    }
  }

  .menu {
    button {
      width: 50%;
      white-space: nowrap;
      @media (max-width: 500px) {
        span {
          font-size: 15px;
        }
      }
      @media (max-width: 450px) {
        span {
          font-size: 13px;
        }
      }
      @media (max-width: 350px) {
        span {
          font-size: 12px;
        }
      }


      &:first-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }

      &:last-child {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;

      }
    }

    margin-bottom: 32px;
  }

  .amount {
    position: relative;

    .currencyWithError {
      position: absolute;
      top: 102px;
      right: 44px;
      font-size: 22px;
      line-height: 30px;

      @media (max-width: 1200px) {
        top: 126px;
      }
    }

    .currencyWithoutError {
      position: absolute;
      top: 82px;
      right: 44px;
      font-size: 22px;
      line-height: 30px;
    }
  }

  .inline-fields {
    display: flex;
    justify-content: space-between;

    & > div {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .commission {
    span {
      padding: 0 16px;
      color: var(--color-placeholder);
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;

      @media (max-width: 1200px) {
        padding: 0 12px;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
      }
    }
  }

  .agree-part {
    display: flex;
    align-items: center;

    .MuiCheckbox-root {
      margin-left: -12px;
      margin-right: 4px;
    }

    a {
      cursor: pointer;
      color: var(--color-primary);
      font-family: 'Regular', sans-serif;
      text-decoration: underline;
    }
  }

  .controls {
    button {
      width: 204px;
    }

    .primary {
      margin-right: 24px;
    }

    display: flex;
    @media (max-width: 700px) {
      flex-direction: column;

      button {
        width: 100% !important;
      }

      .primary {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }

  }

  .error {
    color: #f44336;
    margin-bottom: 5px;
  }

  .icons {
    display: flex;
    align-items: center;
    margin-top: 34px;
    flex-wrap: wrap;

    img {
      padding-bottom: 32px;
      padding-right: 8px;
      margin: auto;

      &:last-child {
        padding-right: 0;
      }
    }
  }

  hr {
    border-width: 0;
    background: var(--color-placeholder);
    height: 1px;
    margin-bottom: 16px;
  }

  .warning {
    color: var(--color-placeholder);
  }

  .finish-step {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &._code {
      height: 300px;
    }

    .h3 {
      display: block;
      width: 100%;
      text-align: left;
    }

    .description {
      text-align: center;
    }
  }
}

.progress {
  animation: spin 2s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
