html {
    --color-primary: #3e7ab7;
    --color-primary-hover: #66a0db;
    --color-white: #FFFFFF;
    --color-text: #343a40;
    --color-text-blue: #002771;
    --color-placeholder: #cccccc ;
    --color-header: #0654a2;
    --color-background-light: #f7f7f7;
}
