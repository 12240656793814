footer.appbar {
  background: linear-gradient(96.5deg, rgb(6, 84, 162) 10.4%, #021830 87.7%);
  position: static;

  a {
    color: var(--color-white) !important;
  }

  @media (max-width: 1200px) {
    display: none;
  }
}

._short {
  background: linear-gradient(96.5deg, rgb(6, 84, 162) 10.4%, #021830 87.7%);
  width: 100%;
  display: flex;
  justify-content: center;
  height: 88px;

  img {
    width: 150px;
  }
  @media (min-width: 1200px) {
    display: none;
  }
}

